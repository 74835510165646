import { Container } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';

export default function Success() {
  return (
   <Container>
     <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Success</Accordion.Header>
        <Accordion.Body>
          Your application has been successfully submitted. You will receive an email soon regarding a status of your application.
          Thank you
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>MENU</Accordion.Header>
        <Accordion.Body>
          <a href="/creditrepair">Credit Repair</a>
          <br />
          <a href="/loan">Interest free loan</a>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
   </Container>
  );
}

