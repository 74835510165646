// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD_wYdDq5KkHMLe9KN5a6g1x7-kcT_N3iU",
  authDomain: "aloqacorp-4eaf5.firebaseapp.com",
  projectId: "aloqacorp-4eaf5",
  storageBucket: "aloqacorp-4eaf5.appspot.com",
  messagingSenderId: "437862255116",
  appId: "1:437862255116:web:9b05b6c1faf492261b7c29",
  measurementId: "G-K7EWYEHHNT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export default app;