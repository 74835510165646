import styled, { css } from "styled-components";
import { useState } from "react";

const ToggleCheckbox = styled.input`
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
`

const Labels = styled.div`
    position: absolute;
    padding: 5px;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    justify-content: space-around;
    z-index: 100;
    box-sizing: border-box;
`

const Label = styled.p`
    font-weight: 600;
    margin: 0;
    width: 50%;
    color: #212529;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */

    font-size: inherit;
`;

const ToggleSlot = styled.div`
    cursor: pointer;
    position: relative;
    background-color: rgb(232, 245, 247); //secondary
    border-radius: 30px;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    height: 100%;

    padding: 5px;
    ${({ $small }) => $small && css`
        padding: 2px;
    `}
`;

const ToggleButton = styled.div`  
    height: 100%;
    width: 50%;
    transition: transform 0.15s cubic-bezier(.39, 1.63, .08, 1.02);  
    /* transition: transform 300ms cubic-bezier(.26, 2, .46, 0.71);     */

    border-radius: 30px;
    background-color: #fff;
    border: none;
    box-shadow: 0 4px 16px hsl(189,89%,90%); // hue secondary

    ${ToggleCheckbox}:checked ~ ${ToggleSlot} & {
    transform: translate(100%);
  }
`;

const Container = styled.div`
    margin: 0 auto 10px;
    max-width: 300px;
    height: 60px;

    ${({ $small }) => $small && css`
        max-width: 150px;
        font-size: 0.75rem;
        height: 34px;
    `}
 `;


export default function RadioButton({ labels, small = false, onChange }) {
    const [selected, setSelected] = useState(labels[0]);

    function toggleSelected() {
        const newSelected = selected === labels[0] ? labels[1] : labels[0];
        setSelected(newSelected);
        onChange(newSelected); // Call the onChange callback with the new selected value
    }

    return (
        <Container $small={small}>
            <label>
                <ToggleCheckbox type="checkbox" onChange={toggleSelected} />
                <ToggleSlot $small={small} >
                    <Labels>
                        <Label>{labels[0]}</Label>
                        <Label>{labels[1]}</Label>
                    </Labels>
                    <ToggleButton />
                </ToggleSlot>
            </label>
        </Container>
    )

}
