import { getDatabase, push, ref } from 'firebase/database';
import React, { useState } from 'react'
import { Card, Container } from 'react-bootstrap';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

export default function Consultation() {
    const [value,onChange] = useState(new Date().toString());
    const [time,setTime] = useState('9:00am');
    const [name,setName] = useState('');
    const [email,setEmail] = useState('');
    const [phone,setPhone] = useState('');
    const [message,setMessage] = useState('');
    const [date,setDate] = useState(new Date().toString());
    const [success,setSuccess] = useState(false);

    console.log(value, typeof value);
    console.log(time);


    function handleSubmit(e){
        e.preventDefault();
        let newSchedule = {
            value,
            time,
            name,
            email,
            phone,
            message,
            date
        }
        push(ref(getDatabase(),`consultation`),newSchedule);
        window.open(`https://buy.stripe.com/7sI14YfTHg2W4aQ4gQ`)
        setSuccess(true)
    }

  return (
    <Container>
        <br />
        <h1>Schedule a consultation</h1>
        <br />
      {success ? <div>
        <p className="green">
            You have scheduled a consultation for the following day and time:
        </p>
        <p>
            {value}
        </p>
        <p>
            {time}
        </p>
        <p>
            Thank you
        </p>
      </div> : <Card style={{padding:20}}>
       <form onSubmit={handleSubmit}>
            <div className="form-group mb-3">
                <label htmlFor="name">Full Name</label>
                <input onChange={(e) => setName(e.target.value)}
                type="text" className="form-control"  required/>
            </div>
            <div className="form-group mb-3">
                <label htmlFor="email">Email Address</label>
                <input onChange={(e) => setEmail(e.target.value)}
                type="email" className="form-control"  required/>
            </div>
            <div className="form-group mb-3">
                <label htmlFor="phone">Phone Number</label>
                <input onChange={(e) => setPhone(e.target.value)}
                type="text" className="form-control"  required/>
            </div>
            <div className="form-group mb-3">
                <label htmlFor="calendar">Choose a date</label>
                <Calendar onChange={(val) => {
                    onChange(val.toString())
                    console.log(val, typeof val)
                    }} value={value} />
            </div>
            <div className="form-group mb-3">
                <label htmlFor="time">Choose a time</label>
                <select 
                onChange={(e) => setTime(e.target.value)}
                className='form-control' name="time" id="time" 
                value={time}
                required>
                    <option value="9:00am">9:00 am</option>
                    <option value="9:30am">9:30 am</option>
                    <option value="10:00am">10:00 am</option>
                    <option value="10:30am">10:30 am</option>
                    <option value="11:00am">11:00 am</option>
                    <option value="11:30am">11:30 am</option>
                    <option value="12:00pm">12:00 pm</option>
                    <option value="12:30pm">12:30 pm</option>
                    <option value="1:00pm">1:00 pm</option>
                    <option value="1:30pm">1:30 pm</option>
                    <option value="2:00pm">2:00 pm</option>
                    <option value="2:30pm">2:30 pm</option>
                    <option value="3:00pm">3:00 pm</option>
                    <option value="3:30pm">3:30 pm</option>
                    <option value="4:00pm">4:00 pm</option>
                    <option value="4:30pm">4:30 pm</option>
                    <option value="5:00pm">5:00 pm</option>
                </select>
            </div>
            <div className="form-group mb-3">
                <label htmlFor="message">Message</label>
                <textarea 
                onChange={(e) => setMessage(e.target.value)}
                className='form-control' name="message" id="" 
                cols="30" rows="10" 
                placeholder='Describe the reason'
                required></textarea>
            </div>
            <div className="form-group mb-3">
                <button className="btn btn-primary">Schedule Now</button>
            </div>
        </form>
       </Card>}
    </Container>
  )
}
