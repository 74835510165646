import styled, {css} from "styled-components";
import {Link} from "react-router-dom";
import { forwardRef } from "react";


const StyledButton = styled.button`
    background-color: hsl(343, 89%, 56%); // primary
    box-shadow: 0 4px 16px hsl(343,89%,90%);
    color: #fff;
    border: none;
    border-radius: 30px;
    padding: 0.5rem 2rem; // 1.25rem for small
    font-family: "Segoe UI";
    font-weight: 700;
    font-size: 1rem;
    box-sizing: border-box;
    display: inline-block;
    line-height: 1.5;
    cursor: pointer;
    transition: all ease 0.3s;
    z-index: 10;

    @media (max-width: 990px) {
        font-size: 0.875rem;
        padding: 0.5rem 1.25rem;
    } 

    ${({$fullWidth})=> $fullWidth && css`width: 100%;`};
    

    ${({$type}) => $type === "secondary" && css`
            background-color: rgb(108, 117, 125);
            box-shadow: none;
    `};

    &:hover {
        background-color: ${({$type})=>$type==="primary" ? "hsl(343, 89%, 40%)" :  "#5c636a"}; //primary-200
    }

`;

const Button = forwardRef(function Button({ type="primary", fullWidth=false, children, ...props }, ref) {
    return <StyledButton ref={ref} $type={type} $fullWidth={fullWidth} {...props}>{children}</StyledButton>;
});

export default Button