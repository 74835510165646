import React from 'react'; 
import { TabView, TabPanel } from 'primereact/tabview';
import FreeLoan from './FreeLoan';
import CreditRepair from './CreditRepair';
import Claim from './Claim';
import ContactForm from './ContactForm';
import Services from './Services';
import Home from './Home';
import Footer from './Footer';
import AboutAloqa from './AboutAloqa';

export default function MainHome() {
    return (
        <div className="card">
            <TabView>
            <TabPanel header="Home">
                   <Services />
                </TabPanel>
             
                <TabPanel header="Contact us">
                    <ContactForm />
                </TabPanel>
            </TabView>
            <Footer />
        </div>
    )
}
        
