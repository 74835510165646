import React, { useCallback, useEffect, useState } from "react";
import { loadConnectAndInitialize } from "@stripe/connect-js";
import {
  ConnectPayments,
  ConnectComponentsProvider,
} from "@stripe/react-connect-js";
import "./App.css";
import { API_URL, STRIPE_PK } from "./keys";
import { useParams } from "react-router-dom";

export default function Account() {
  const { id } = useParams();
  const [pk,setPK] = useState(STRIPE_PK);
 // const [accountId,setAccountId] = useState('acct_1PaqdFRthU9Ufeo1');
  const [stripeConnectInstance] = useState(() => {
    const fetchClientSecret = async () => {
      // Fetch the AccountSession client secret
      const response = await fetch(`${API_URL}/account_session`, { 
        method: "POST", 
        body:JSON.stringify({accountId:id}),
        headers:{ 'Content-Type':'Application/json' } 
      });
      if (!response.ok) {
        // Handle errors on the client side here
        const {error} = await response.json();
        console.log('An error occurred: ', error);
        return undefined;
      } else {
        const {client_secret: clientSecret} = await response.json();
        return clientSecret;
      }
    };
    return loadConnectAndInitialize({
        // This is your test publishable API key.
        publishableKey: pk,
        fetchClientSecret: fetchClientSecret,
        appearance: {
          overlays: 'dialog',
          variables: {
            colorPrimary: '#625afa',
          },
        },
      })
    });

   // console.log('id from param', id);

   const fetchPk = useCallback(async () => {
    let pkrow = await fetch(`${API_URL}/pk`);
    let pkdata = await pkrow.json();
    console.log('pk: ',pkdata)
    setPK(pkdata.pk)
   },[pk])

   useEffect(() => {
    fetchPk()
   },[])

   //console.log('publickey: ',pk)

  return (
    <div className="container">
        <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
          <ConnectPayments />
        </ConnectComponentsProvider>
    </div>
  )
}