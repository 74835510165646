import { getDatabase, onValue, push, ref, remove, set } from 'firebase/database';
import { useCallback, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Stack from 'react-bootstrap/Stack';
import { FaTrash } from "react-icons/fa";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { IoCopyOutline } from "react-icons/io5";

function FreeLoanApps() {
    const [apps,setApps] = useState([]);

    const fetchAppsCB = useCallback(() => {
        onValue(ref(getDatabase(),`loans`),(dataSnapshot) => {
            let dataArray = [];
            dataSnapshot.forEach(data => {
                dataArray.push(data)
               // console.log(data.val())
            })
            setApps(dataArray.reverse())
        })
    },[apps])


    useEffect(() => {
        fetchAppsCB()
    },[])

    function removeItem(item){
        remove(ref(getDatabase(),`loans/${item?.key}`))
        push(ref(getDatabase(),`helpuztrash`),{
            key:item.key,
            ...item.val(),
            topic:'loan'
        })
        alert('Item moved to trash successfully')
    }

  return (
    <Container>
        <h1>Free loan applications</h1>
       {
        apps?.map((item,index) => (
            <Stack 
            key={index}
            gap={3}
            className='stack'
            >
            <div className="p-2">
                <div className='my-row'>
                <p>FIRST NAME: 
                    {item?.val()?.firstName}
                    <CopyToClipboard text={item?.val()?.firstName}
                    onCopy={() => console.log(item?.val()?.firstName)}>
                    <IoCopyOutline />
                    </CopyToClipboard>
                    </p>
                <p>
                <FaTrash onClick={() => removeItem(item)} />
                </p>
                </div>
                <p>
                    LAST NAME: {item?.val()?.lastName}
                    <CopyToClipboard text={item?.val()?.lastName}
                    onCopy={() => console.log(item?.val()?.lastName)}>
                    <IoCopyOutline />
                    </CopyToClipboard>
                </p>
                <p>
                    EMAIL: {item?.val()?.email}
                    <CopyToClipboard text={item?.val()?.email}
                    onCopy={() => console.log(item?.val()?.email)}>
                    <IoCopyOutline />
                    </CopyToClipboard>
                    </p>
                <p>
                    PHONE: {item?.val()?.phone}
                    <CopyToClipboard text={item?.val()?.phone}
                    onCopy={() => console.log(item?.val()?.phone)}>
                    <IoCopyOutline />
                    </CopyToClipboard>
                    </p>
                <p>
                    STREET: {item?.val()?.street}
                    <CopyToClipboard text={item?.val()?.street}
                    onCopy={() => console.log(item?.val()?.street)}>
                    <IoCopyOutline />
                    </CopyToClipboard>
                    </p>
                <p>
                    CITY: {item?.val()?.city}
                    <CopyToClipboard text={item?.val()?.city}
                    onCopy={() => console.log(item?.val()?.city)}>
                    <IoCopyOutline />
                    </CopyToClipboard>
                    </p>
                <p>
                    STATE: {item?.val()?.state}
                    <CopyToClipboard text={item?.val()?.state}
                    onCopy={() => console.log(item?.val()?.state)}>
                    <IoCopyOutline />
                    </CopyToClipboard>
                    </p>
                <p>
                    ZIP: {item?.val()?.zip}
                    <CopyToClipboard text={item?.val()?.zip}
                    onCopy={() => console.log(item?.val()?.zip)}>
                    <IoCopyOutline />
                    </CopyToClipboard>
                    </p>
                <p>
                    SSN: {item?.val()?.ssn}
                    <CopyToClipboard text={item?.val()?.ssn}
                    onCopy={() => console.log(item?.val()?.ssn)}>
                    <IoCopyOutline />
                    </CopyToClipboard>
                    </p>

                    <p>
                    AMOUNT: {item?.val()?.amount}
                    <CopyToClipboard text={item?.val()?.amount}
                    onCopy={() => console.log(item?.val()?.amount)}>
                    <IoCopyOutline />
                    </CopyToClipboard>
                    </p>
                    <p>
                    REASON: {item?.val()?.reason}
                    <CopyToClipboard text={item?.val()?.reason}
                    onCopy={() => console.log(item?.val()?.reason)}>
                    <IoCopyOutline />
                    </CopyToClipboard>
                    </p>

                    {item?.val()?.stateId?.length > 0 && <img src={item?.val()?.stateId[0]} alt="img" style={{width:'100%',height:'auto'}}/>}
                    <p>Bank Statements:</p>
                    {
                        item?.val()?.bankStatements?.length > 0 && <>
                        {
                            item?.val()?.bankStatements?.map((b,i) => (
                                <p key={i}><a href={b} download={true}>{b?.slice(-11)}</a></p>
                            ))
                        }
                        </>
                    }
                    <p>Business tax returns:</p>
                    {
                        item?.val()?.businessTaxReturn?.length > 0 && <>
                        {
                            item?.val()?.businessTaxReturn?.map((b,i) => (
                                <p key={i}><a href={b} download={true}>{b?.slice(-11)}</a></p>
                            ))
                        }
                        </>
                    }
                      <p>Personal tax returns:</p>
                    {
                        item?.val()?.personaltaxtreturn?.length > 0 && <>
                        {
                            item?.val()?.personaltaxtreturn?.map((b,i) => (
                                <p key={i}><a href={b} download={true}>{b?.slice(-11)}</a></p>
                            ))
                        }
                        </>
                    }
                    <p>{item?.val()?.date}</p>
            </div>
            </Stack>
        ))
       }
    </Container>
  );
}

export default FreeLoanApps;