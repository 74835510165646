import { getDatabase, onValue, push, ref, remove } from 'firebase/database';
import { useCallback, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Stack from 'react-bootstrap/Stack';
import { FaTrash } from "react-icons/fa";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { IoCopyOutline } from "react-icons/io5";

export default function StudentsData() {
    const [apps,setApps] = useState([]);

    const fetchAppsCB = useCallback(() => {
        onValue(ref(getDatabase(),`students`),(dataSnapshot) => {
            let dataArray = [];
            dataSnapshot.forEach(data => {
                dataArray.push(data)
               // console.log(data.val())
            })
            setApps(dataArray.reverse())
        })
    },[apps])


    useEffect(() => {
        fetchAppsCB()
    },[])

    function removeItem(item){
        remove(ref(getDatabase(),`students/${item?.key}`))
        push(ref(getDatabase(),`helpuztrash`),{
            key:item.key,
            ...item.val(),
            topic:'students'
        })
        alert('Deleted successfully')
    }

  return (
    <Container>
        <h1>International student applications</h1>
      <div>
      {
        apps?.map((item,index) => (
            <Stack 
            key={index}
            gap={3}
            className='stack'
            >
            <div className="p-2">
                <div className='my-row'>
                <p>FIRST NAME: {item?.val()?.firstName}</p>
                <p>
                <FaTrash onClick={() => removeItem(item)} />
                </p>
                </div>
                <p>
                    LAST NAME: {item?.val()?.lastName}
                    <CopyToClipboard text={item?.val()?.lastName}
                    onCopy={() => console.log(item?.val()?.lastName)}>
                    <IoCopyOutline />
                    </CopyToClipboard>
                </p>
                <p>
                    EMAIL: {item?.val()?.email}
                    <CopyToClipboard text={item?.val()?.email}
                    onCopy={() => console.log(item?.val()?.email)}>
                    <IoCopyOutline />
                    </CopyToClipboard>
                    </p>
                <p>
                    PHONE: {item?.val()?.phone}
                    <CopyToClipboard text={item?.val()?.phone}
                    onCopy={() => console.log(item?.val()?.phone)}>
                    <IoCopyOutline />
                    </CopyToClipboard>
                    </p>
                <p>
                    STREET: {item?.val()?.street}
                    <CopyToClipboard text={item?.val()?.street}
                    onCopy={() => console.log(item?.val()?.street)}>
                    <IoCopyOutline />
                    </CopyToClipboard>
                    </p>
                <p>
                    CITY: {item?.val()?.city}
                    <CopyToClipboard text={item?.val()?.city}
                    onCopy={() => console.log(item?.val()?.city)}>
                    <IoCopyOutline />
                    </CopyToClipboard>
                    </p>
                <p>
                    STATE: {item?.val()?.state}
                    <CopyToClipboard text={item?.val()?.state}
                    onCopy={() => console.log(item?.val()?.state)}>
                    <IoCopyOutline />
                    </CopyToClipboard>
                    </p>
                <p>
                    ZIP: {item?.val()?.zip}
                    <CopyToClipboard text={item?.val()?.zip}
                    onCopy={() => console.log(item?.val()?.zip)}>
                    <IoCopyOutline />
                    </CopyToClipboard>
                    </p>
           
                    {item?.val()?.stateId?.length > 0 && <img src={item?.val()?.stateId[0]} alt="img" style={{width:'100%',height:'auto'}}/>}
                    <p>Documents: </p>
                    {item?.val()?.studentDocuments?.length > 0 && item?.val()?.studentDocuments?.map((doc,index) => (
                        <div key={index}>
                            <p><a href={doc} download={true}>{doc.slice(-20)} {index+1}</a></p>
                        </div>
                    ))}
                    <p>{item?.val()?.date}</p>
            </div>
            </Stack>
        ))
       }
      </div>
    </Container>
  );
}

