import { useState } from 'react';
import { Card, Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Header from './Header';
import { getDatabase, push, ref } from 'firebase/database';
import { corporation } from './data';

function ContactForm() {
  const [validated, setValidated] = useState(false);
  const [fname,setFname] = useState('');
  const [lname,setLname] = useState('');
  const [email,setEmail] = useState('');
  const [phone,setPhone] = useState('');
  const [message,setMessage] = useState('');
  const [successMessage,setSuccessMessage] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    push(ref(getDatabase(),`aloqacorpcontacts`),{
        fname,
        lname,
        email,
        phone,
        message,
        date:new Date().toString()
    })
    setSuccessMessage('Form has been submitted successfully. We will reply you back via email')
  };

  return (
    <Container style={{paddingTop:50}}>
        {/* <Header /> */}
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <h1>
                {
                    successMessage ? `We received your message` : `Leave us a message`
                }
            </h1>
      <Row className="mb-3">
        <Form.Group as={Col} md="6" controlId="validationCustom01">
          <Form.Label>First name</Form.Label>
          <Form.Control
          value={fname}
          onChange={(e) => setFname(e.target.value)}
            required
            type="text"
          />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="validationCustom02">
          <Form.Label>Last name</Form.Label>
          <Form.Control
          value={lname}
           onChange={(e) => setLname(e.target.value)}
            required
            type="text"
          />
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="12" controlId="validationCustomUsername">
          <Form.Label>Phone</Form.Label>
          <InputGroup hasValidation>
            <Form.Control
            value={phone}
             onChange={(e) => setPhone(e.target.value)}
              type="text"
              aria-describedby="inputGroupPrepend"
            />
            <Form.Control.Feedback type="invalid">
              Please choose a phone.
            </Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="12" controlId="validationCustom03">
          <Form.Label>Message</Form.Label>
          <Form.Control
          value={message}
           onChange={(e) => setMessage(e.target.value)}
           as={'textarea'} type="text" required />
          <Form.Control.Feedback type="invalid">
            Please provide a message.
          </Form.Control.Feedback>
        </Form.Group>
       
      </Row>
      <Form.Group className='mb-3 d-grid gap-2' >
      <Button type="submit">Submit</Button>
      </Form.Group>
    </Form>


    <br />

    <Card style={{padding:20}}>
        <Card.Text>
            <a href={`mailto:${corporation.email}`}>{corporation.email}</a>
        </Card.Text>
        <Card.Text>
            <a href={`tel:${corporation.phone}`}>{corporation.phone}</a>
        </Card.Text>
        <Card.Text>
            <a href={'#'}>{corporation.address}</a>
        </Card.Text>
    </Card>
    </Container>
  );
}

export default ContactForm;