import React, { useState } from "react";
import styled from "styled-components";

const Label = styled.label`
    margin-bottom: 0.5rem;
    opacity: 0.7;
    display: inline-block;
`;

const Input = styled.input`
    box-sizing: border-box;
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: white;
    background-clip: padding-box;
    border: 1px solid #dee2e6;
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

    &:focus {
        border-color: #86b7fe;
        outline: 0;
        box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
    }

    &:disabled {
        opacity: 1;
        background-color: #e9ecef;
    }
`;

const Container = styled.div`
    text-align: start;
    margin-bottom: 0.5rem;
    width: 100%;
`;

export default function FormInput({ maxLength, id, title, value, onChange, ...props }) {
	return (
		<Container>
			<Label htmlFor={id}>{title}</Label>
			<Input maxLength={maxLength} id={id} value={value} onChange={onChange} {...props} />
		</Container>
	);
}