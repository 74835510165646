import { getDatabase, onValue, ref, remove } from 'firebase/database';
import { useCallback, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';

function ContactedUsers() {
    const [contactedUsers,setContactedUsers] = useState([]);

    const fetchContactedUsersCB = useCallback(() => {
        onValue(ref(getDatabase(),`aloqacorpcontacts`),(users) => {
            let usersArray = []
            users.forEach(user => {
                usersArray.push(user)
            }) 
            setContactedUsers(usersArray)
        })
    },[contactedUsers])

    useEffect(() => {
        fetchContactedUsersCB()
    },[])

    function removeContact(key){
        remove(ref(getDatabase(),`aloqacorpcontacts/${key}`))
        alert('removed success')
    }

  return (
    <Container>
        <h3>Contacted users</h3>
        {
            contactedUsers?.map((user,index) => (
                <ListGroup key={index}>
                <ListGroup.Item>{user?.val().fname}</ListGroup.Item>
                <ListGroup.Item>{user?.val().lname}</ListGroup.Item>
                <ListGroup.Item>{user?.val().email}</ListGroup.Item>
                <ListGroup.Item>{user?.val().phone}</ListGroup.Item>
                <ListGroup.Item>{user?.val().message}</ListGroup.Item>
                <ListGroup.Item>{user?.val().date}</ListGroup.Item>
                <ListGroup.Item style={{color:'red'}}
                onClick={() => removeContact(user?.key)}>delete</ListGroup.Item>
                <hr />
                </ListGroup>
            ))
        }
    </Container>
  );
}

export default ContactedUsers;