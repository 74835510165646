import { Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

function CreditRepairSuccess() {
  return (
    <Container>
        <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>Success</Card.Title>
        <Card.Text>
          We received your request for credit repair service. Expect email from Lexington Law regarding a status of your service.
        </Card.Text>
      </Card.Body>
    </Card>
    </Container>
  );
}

export default CreditRepairSuccess;