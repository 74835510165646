import React from 'react'
import { Card, Container } from 'react-bootstrap'
import { corporation } from './data'
import Services from './Services'


export default function About() {
  return (
    <Container>
          <Card style={{ width: '100%' }}>
      <Card.Body>
        {/* <Card.Title>{corporation.name}</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">{corporation.about}</Card.Subtitle> */}
        <Card.Text>
            <Services />
        </Card.Text>
        <Card.Footer>
        <Card.Link href={`/contact`}>Contact Us</Card.Link>
        <br />
        <Card.Link href={`/privacy`}>Privacy Policy</Card.Link>
        <Card.Text>
          {corporation.address}
        </Card.Text>
        </Card.Footer>
      </Card.Body>
    </Card>
    </Container>
  )
}
