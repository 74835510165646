import React from 'react'
import { Alert, Col, Container, Navbar, Row } from 'react-bootstrap'
import Footer from './Footer'

export default function Order() {
  return (
    <Container>
      
      <h1 style={{padding:20}}>
        Website Design and Development <span style={{color:'yellowgreen'}}>Packages</span>
        </h1>

        <Row>
            <Col md={'6'}>
                <Alert variant='primary'>
                    <h3>Standard <span style={{color:'blue'}}>$499</span></h3>
                    <ul>
                        <li>3 Page Website</li>
                        <li>Perfect for Startups</li>
                        <li>Theme Based Design</li>
                        <li>Original Content</li>
                        <li>Complete in 5 Business Days</li>
                        <li>Content Management System</li>
                        <li>Sliders</li>
                        <li>Responsive Design</li>
                        <li>Social Media Integration</li>
                        <li>SEO Features</li>
                        <li>Blog Setup</li>
                        <li>Contact Forms Included</li>
                        <li>Stock Photography</li>
                        <li>Multi-Language Support</li>
                        <li>3 Final Draft Revisions</li>
                        <li>1 Month Maintenance</li>
                    </ul>
               
                    <a href="https://buy.stripe.com/14k8xqgXL3gacHmcNg">
                    <button className='btn btn-primary'>Order Now</button>
                    </a>
                </Alert>
            </Col>

            <Col md={'6'}>
            <Alert variant='success'>
                    <h3>Professional <span style={{color:'blue'}}>$995</span> </h3>
                    <ul>
                        <li>5 Page Website</li>
                        <li>Perfect for Small Businesses</li>
                        <li>Custom Design</li>
                        <li>Original Content</li>
                        <li>Complete in 2 Weeks</li>
                        <li>Content Management System</li>
                        <li>Sliders</li>
                        <li>Responsive Design</li>
                        <li>Social Media Integration</li>
                        <li>SEO Features</li>
                        <li>Blog Setup</li>
                        <li>Contact Forms Included</li>
                        <li>Stock Photography</li>
                        <li>Multi-Language Support</li>
                        <li>5 Final Draft Revisions</li>
                        <li>1 Month Maintenance</li>
                    </ul>
                    <a href="https://buy.stripe.com/00g6picHvg2W4aQ8x1">
                    <button className='btn btn-primary'>Order Now</button>
                    </a>
                </Alert>
            </Col>

            <Col md={'6'}>
            <Alert variant='info'>
                    <h3>Templated <span style={{color:'blue'}}>$349</span> </h3>
                    <ul>
                        <li>3 Page Website</li>
                        <li>Perfect for Startups</li>
                        <li>No Content Included</li>
                        <li>Theme Based Design</li>
                        <li>Complete in 5 Business Days</li>
                        <li>Content Management System</li>
                        <li>Sliders</li>
                        <li>Responsive Design</li>
                        <li>Social Media Integration</li>
                        <li>SEO Features</li>
                        <li>Blog Setup</li>
                        <li>Contact Forms Included</li>
                        <li>Stock Photography</li>
                        <li>Multi-Language Support</li>
                        <li>3 Final Draft Revisions</li>
                        <li>1 Month Maintenance</li>
                    </ul>
                    <a href="https://buy.stripe.com/dR6dRK4aZ5oi0YEcNi">
                    <button className='btn btn-primary'>Order Now</button>
                    </a>
                </Alert>
            </Col>
        </Row>
        <Footer/>
    </Container>
  )
}
