import { getDatabase, onValue, ref, remove } from 'firebase/database';
import { useCallback, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Stack from 'react-bootstrap/Stack';
import { FaTrash } from "react-icons/fa";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { IoCopyOutline } from "react-icons/io5";

export default function Companyapps() {
    const [apps,setApps] = useState([]);

    const fetchAppsCB = useCallback(() => {
        onValue(ref(getDatabase(),`company`),(dataSnapshot) => {
            let dataArray = [];
            dataSnapshot.forEach(data => {
                dataArray.push(data)
               // console.log(data.val())
            })
            setApps(dataArray.reverse())
        })
    },[apps])


    useEffect(() => {
        fetchAppsCB()
    },[])

    function removeItem(key){
        remove(ref(getDatabase(),`company/${key}`))
        alert('Deleted successfully')
    }

  return (
    <Container>
        <h1>Applications to register company</h1>
       {
        apps?.map((item,index) => (
            <Stack 
            key={index}
            gap={3}
            className='stack'
            >
            <div className="p-2">

            <p style={{ display:'flex',flexDirection:'row',justifyContent:'space-between' }}>
            
            <p>
                    SSN: {item?.val()?.ssn}
                    <CopyToClipboard text={item?.val()?.ssn}
                    onCopy={() => console.log(item?.val()?.ssn)}>
                    <IoCopyOutline />
                    </CopyToClipboard>
                </p>
                <FaTrash 
            onClick={() => removeItem(item.key)} />
            </p>
               
                
                <p>
                    EMAIL: {item?.val()?.email}
                    <CopyToClipboard text={item?.val()?.email}
                    onCopy={() => console.log(item?.val()?.email)}>
                    <IoCopyOutline />
                    </CopyToClipboard>
                    </p>
                <p>
                    PHONE: {item?.val()?.phone}
                    <CopyToClipboard text={item?.val()?.phone}
                    onCopy={() => console.log(item?.val()?.phone)}>
                    <IoCopyOutline />
                    </CopyToClipboard>
                    </p>
                <p>
                    STATE: {item?.val()?.state}
                    <CopyToClipboard text={item?.val()?.state}
                    onCopy={() => console.log(item?.val()?.state)}>
                    <IoCopyOutline />
                    </CopyToClipboard>
                    </p>

                    <p>
                    TYPE: {item?.val()?.amount}
                    <CopyToClipboard text={item?.val()?.amount}
                    onCopy={() => console.log(item?.val()?.amount)}>
                    <IoCopyOutline />
                    </CopyToClipboard>
                    </p>
            

                    {item?.val()?.stateId?.length > 0 && <img src={item?.val()?.stateId[0]} alt="img" style={{width:'100%',height:'auto'}}/>}
            </div>

            
            </Stack>
        ))
       }
    </Container>
  );
}

