import React, { useState } from "react";
import styled from "styled-components";
import FormInput from "../ui/FormInput";
import RadioButton from "../ui/RadioButton";
import Button from "../ui/Button";
import { STRIPE_PK, STRIPE_SK } from "../components/STRIPEKEYS";

const PaymentCardContainer = styled.div`
    width: 68.666%;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 10px 30px rgba(51, 51, 51, 0.1);
    padding: 1rem;

    @media(max-width: 786px) {
        width: 100%;
    }
`;

const PaymentContainer = styled.div`
    margin: 1rem 0;
    display: flex;
    gap: 1.5rem;
    box-sizing: border-box;
    align-items: stretch;
    
    @media(max-width: 786px) {
        flex-direction: column;
    }
`;

const SummaryCard = styled.div`
    box-sizing: border-box;
	border-radius: 10px;
	background-color: white;
	box-shadow: 0 10px 30px rgba(51, 51, 51,0.1);
	padding: 1rem;

    border: 1px solid hsl(189, 70%, 51%);
    width: 33.333%;

    @media(max-width: 786px) {
        width: 100%;
    }
`;

const SpaceBetweenFlex = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const PlanDiv = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-top: 0.25rem;
`;

const BillingDiv = styled(SpaceBetweenFlex)`
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
    padding: 0.5rem 0;
    margin: 0.25rem 0;
    margin-bottom: 0.75rem;
`;

const SwitchContainer = styled.div`
    flex-basis: 150px;
    height: 34px;
`;

const Small = styled.small`
    font-size: 0.875rem;
`;

const ButtonContainer = styled.div`
    margin-top: 1.25rem;
`;

const H6 = styled.h6`
    font-size: 1rem;
    line-height: 1.2;
    margin: 0;
    padding: 0;
    text-align: start;
    margin-bottom: 1rem;
`;


const FlexDiv = styled.div`
    display: flex;
    width: 100%;
    column-gap: 1.5rem;

    @media(max-width: 412px) {
        flex-direction: column;

    }
`;

export default function PaymentCard() {

    const [interval, setInterval] = useState('year');
    console.log(interval);


    const handleChange = (selectedValue) => {
        if (selectedValue === 'Yearly') {
            setInterval('year')
        } else {
            setInterval('month')
        }
    };


    const [cardNumber, setCardNumber] = useState("");
    const [nameOnCard, setNameOnCard] = useState("");
    const [expiryMonth, setExpiryMonth] = useState("");
    const [expiryYear, setExpiryYear] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [cvc, setCvc] = useState("");

    const handleInputChange = (e, setStateFunction, maxLength) => {
        let { value } = e.target;

        // Ensure only numeric characters are allowed
        if (!/^\d*$/.test(value)) return;

        // Limit the number of characters
        if (value.length > maxLength) {
            value = value.slice(0, maxLength);
        }

        setStateFunction(value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Prepare card data
        const cardData = {
            number: cardNumber,
            exp_month: expiryMonth,
            exp_year: expiryYear,
            cvc: cvc
        };

        // Make a POST request to create a card token
        try {
            const response = await fetch("https://api.stripe.com/v1/tokens", {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${STRIPE_PK}`, // Replace YOUR_STRIPE_SECRET_KEY with your actual secret key
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                body: `card[number]=${cardData.number}&card[exp_month]=${cardData.exp_month}&card[exp_year]=${cardData.exp_year}&card[cvc]=${cardData.cvc}`
            });

            if (!response.ok) {
                throw new Error("Failed to create card token");
            }

            const responseData = await response.json();
            const cardResponse = responseData;

            // Now you can use the card token in place of card details in your API call
            const orderData = {
                plan: 'basic',
                imageCredit: 50,
                bestPhotoCredit: 50,
                bioCredit: 50,
                interval,
                card: cardResponse, // Use the card token here
                amount:11
            };


            // Add backend api to create subscription


           let result = await fetch(`http://localhost:4000/transfer-money`,{
                method:'POST',
                body:JSON.stringify(orderData),
                headers:{ 'Content-Type':'Application/json' }
            })
            // Proceed with further processing, such as submitting the orderData to your backend
            console.log("Order data:", orderData);
            console.log('result',result);
        } catch (error) {
            console.error("Error:", error.message);
        }
    };


    return (
        <form onSubmit={handleSubmit}>
            <PaymentContainer>
                <PaymentCardContainer>
                    <H6>Payment Method</H6>
                    <FlexDiv>
                        <FormInput
                            title={"Card Number"}
                            id={"cardNumber"}
                            value={cardNumber}
                            onChange={(e) => handleInputChange(e, setCardNumber, 16)}
                            type="text"
                            maxLength={16}
                        />
                        <FormInput
                            title={"Name on card"}
                            id={"nameOnCard"}
                            value={nameOnCard}
                            onChange={(e) => setNameOnCard(e.target.value)}
                            type="text"
                        />
                    </FlexDiv>
                    <FlexDiv>
                        <FormInput
                            title={"Expiry Month"}
                            id={"expiryMonth"}
                            value={expiryMonth}
                            onChange={(e) => handleInputChange(e, setExpiryMonth, 2)}
                            type="text"
                            maxLength={2}
                        />
                        <FormInput
                            title={"Expiry Year"}
                            id={"expiryYear"}
                            value={expiryYear}
                            onChange={(e) => handleInputChange(e, setExpiryYear, 4)}
                            type="text"
                            maxLength={4}
                        />
                        <FormInput
                            title={"CVC"}
                            id={"cvc"}
                            value={cvc}
                            onChange={(e) => handleInputChange(e, setCvc, 3)}
                            type="text"
                            maxLength={3}
                        />
                        <FormInput
                            title={"Zip Code"}
                            id={"zipCode"}
                            value={zipCode}
                            onChange={(e) => handleInputChange(e, setZipCode, 5)}
                            type="text"
                            maxLength={5}
                        />
                    </FlexDiv>
                </PaymentCardContainer>
                <SummaryCard>
                    <H6>Order Summary</H6>
                    <PlanDiv>
                        <SpaceBetweenFlex>
                            <Small>Plan</Small> <b>Basic</b>
                        </SpaceBetweenFlex>
                        <SpaceBetweenFlex>
                            <Small>Image Credit(s)</Small> <b>50</b>
                        </SpaceBetweenFlex>
                        <SpaceBetweenFlex>
                            <Small>Best Photo Credit(s)</Small> <b>50</b>
                        </SpaceBetweenFlex>
                        <SpaceBetweenFlex>
                            <Small>Bio Credit(s)</Small> <b>50</b>
                        </SpaceBetweenFlex>
                    </PlanDiv>

                    <BillingDiv>
                        <Small>Billing</Small>
                        <SwitchContainer >
                            <RadioButton labels={["Yearly", "Monthly"]} onChange={handleChange} small={true} />
                        </SwitchContainer>
                    </BillingDiv>


                    <SpaceBetweenFlex>
                        <Small>Total</Small> <b>$300.00</b>
                    </SpaceBetweenFlex>

                    <ButtonContainer >
                        <Button fullWidth>Pay Now</Button>
                    </ButtonContainer>

                </SummaryCard>
            </PaymentContainer>
        </form>
    );
}

