import React from 'react';
import { Container, Navbar } from 'react-bootstrap';
import { corporation } from './data';

export default function Footer() {
  return (
    <Navbar style={{marginTop:100}}
    className="bg-body-tertiary">
     <Container style={{display:'flex',flexDirection:'column'}}>
       <p>Contact Us</p>
       <p style={{fontSize:10}}><a href={`tel:${corporation.phone}`}>{corporation.phone}</a></p>
       <p style={{fontSize:10}}><a href={`mailto:${corporation.email}`}>{corporation.email}</a></p>
       <p style={{fontSize:10}}><a href="/privacy">Privacy Policy</a></p>
     </Container>
   </Navbar>
  )
}
