import React, { useState } from 'react'
import { Alert, Container } from 'react-bootstrap'


export default function OnboardSuccess() {
    const [show, setShow] = useState(true);
  return (
    <Container style={{paddingTop:50}}>
          <Alert 
          onClose={() => setShow(false)}
          variant="success"
          dismissible>
        <Alert.Heading>Success</Alert.Heading>
        <p>
           You have successfully finished onboarding process. Your account is active now
        </p>
        <hr />
        <p className="mb-0">
            Go back to mobile app to continue.
        </p>
        </Alert>
    </Container>
  )
}
