import { getDatabase, onValue, push, ref, remove } from 'firebase/database';
import { useCallback, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Stack from 'react-bootstrap/Stack';
import { FaTrash } from "react-icons/fa";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { IoCopyOutline } from "react-icons/io5";

function Claimapps() {
    const [apps,setApps] = useState([]);

    const fetchAppsCB = useCallback(() => {
        onValue(ref(getDatabase(),`claim`),(dataSnapshot) => {
            let dataArray = [];
            dataSnapshot.forEach(data => {
                dataArray.push(data)
               // console.log(data.val())
            })
            setApps(dataArray.reverse())
        })
    },[apps])


    useEffect(() => {
        fetchAppsCB()
    },[])

    function removeItem(item){
        remove(ref(getDatabase(),`claim/${item?.key}`))
        push(ref(getDatabase(),`helpuztrash`),{
            key:item.key,
            ...item.val(),
            topic:'claim'
        })
        alert('Deleted successfully')
    }

  return (
    <Container>
        <h1>Claim applications</h1>
       {
        apps?.map((item,index) => (
            <Stack 
            key={index}
            gap={3}
            className='stack'
            >
            <div className="p-2">
                <div className='my-row'>
                <p>FIRST NAME: {item?.val()?.firstName}
                <CopyToClipboard text={item?.val()?.firstName}
                    onCopy={() => console.log(item?.val()?.firstName)}>
                    <IoCopyOutline />
                    </CopyToClipboard>
                </p>
                <p>
                <FaTrash onClick={() => removeItem(item)} />
                </p>
                </div>
                <p>
                    LAST NAME: {item?.val()?.lastName}
                    <CopyToClipboard text={item?.val()?.lastName}
                    onCopy={() => console.log(item?.val()?.lastName)}>
                    <IoCopyOutline />
                    </CopyToClipboard>
                </p>
                <p>
                    EMAIL: {item?.val()?.email}
                    <CopyToClipboard text={item?.val()?.email}
                    onCopy={() => console.log(item?.val()?.email)}>
                    <IoCopyOutline />
                    </CopyToClipboard>
                    </p>
                <p>
                    PHONE: {item?.val()?.phone}
                    <CopyToClipboard text={item?.val()?.phone}
                    onCopy={() => console.log(item?.val()?.phone)}>
                    <IoCopyOutline />
                    </CopyToClipboard>
                    </p>
                <p>
                    MESSAGE: {item?.val()?.message}
                    <CopyToClipboard text={item?.val()?.message}
                    onCopy={() => console.log(item?.val()?.message)}>
                    <IoCopyOutline />
                    </CopyToClipboard>
                    </p>

                    <p>
                    COMPANY NAME: {item?.val()?.amount}
                    <CopyToClipboard text={item?.val()?.amount}
                    onCopy={() => console.log(item?.val()?.amount)}>
                    <IoCopyOutline />
                    </CopyToClipboard>
                    </p>
            

                    {item?.val()?.stateId?.length > 0 && <img src={item?.val()?.stateId[0]} alt="img" style={{width:'100%',height:'auto'}}/>}
            </div>
            </Stack>
        ))
       }
    </Container>
  );
}

export default Claimapps;