import { getDatabase, push, ref } from 'firebase/database';
import React, { useState } from 'react'
import { Alert, Button, Card, Container, Form } from 'react-bootstrap';
import { useParams } from 'react-router';

export default function PaymentLinkSuccess(props) {
  const { id } = useParams();
  const [email,setEmail] = useState('');
  const [message,setMessage] = useState('');
  const [loading,setLoading] = useState(false);
  const [successMessage,setSuccessMessage] = useState('');

   async function handleSubmit(e){
        e.preventDefault();
        setLoading(true)
        try {
            let newOrder = {
                amount:id,
                email,
                message,
                date:new Date().toString()
            }
            push(ref(getDatabase(),`orders`),newOrder);
            setLoading(false)
            console.log('success')
            setSuccessMessage('We have received your order and we are currently working on it. We will email you soon. Thank you')
            setEmail('');
            setMessage('');
        } catch (error) {
            console.log(error);
            alert('Something went wrong. Try again later')
            setLoading(false)
        }
    }
  return (
    <Container style={{padding:10}}>
        <h1>Complete your order</h1>
        <Card style={{padding:20}}>
      {successMessage ? <Alert variant='success' dismissible onClose={() => setSuccessMessage('')}>{successMessage}</Alert> :  <Form 
        onSubmit={handleSubmit}
        >
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Email address</Form.Label>
        <Form.Control onChange={(e) => setEmail(e.target.value)}
        type="email" placeholder="" required value={email}/>
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Describe your project in details</Form.Label>
        <Form.Control onChange={(e) => setMessage(e.target.value)}
        as="textarea" rows={3}  required value={message} />
      </Form.Group>
      <Form.Group className='d-grid gap-2'>
        <Button type='submit'>
            {
                loading ? 'Submitting..' : 'Submit'
            }
        </Button>
      </Form.Group>
    </Form>}
        </Card>
    </Container>
  )
}
